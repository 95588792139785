import React, {useEffect, useMemo, useReducer} from 'react';
import {defaultValue, GlobalContext} from "./GlobalContext";
import {useBackstageIdentityApi} from "../hooks";
import {globalReducer} from "./globalReducer";
import {setBackstageUserIdentity, setPlexoRoles} from "./globalActions";



type Props = {
  children: React.ReactNode;
};


export const GlobalProvider = ({ children }: Props) => {

  const { getBackstageUserIdentity, backstageUserIdentity } = useBackstageIdentityApi();
  const [state, dispatch] = useReducer(globalReducer, defaultValue);


  useEffect(() => {
    getBackstageUserIdentity();
  }, []);


  useEffect(() => {
    if (backstageUserIdentity) {
      dispatch(setBackstageUserIdentity(backstageUserIdentity));
    }
  }, [backstageUserIdentity]);

  const contextValue = useMemo(() => ({
    ...state,
    setPlexoRoles: (roles: string[]) => dispatch(setPlexoRoles(roles)),
  }), [state]);

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};
