import {defaultValue} from "./GlobalContext";
import {BackstageUserIdentity} from "@backstage/core-plugin-api";

type Action =
  | { type: 'SET_BACKSTAGE_USER_IDENTITY'; payload: BackstageUserIdentity }
  | { type: 'SET_PLEXO_ROLES'; payload: string[] };

export const globalReducer = (state: typeof defaultValue, action: Action) => {
  switch (action.type) {
    case 'SET_BACKSTAGE_USER_IDENTITY':
      return { ...state, backstageUserIdentity: action.payload };
    case 'SET_PLEXO_ROLES':
      return { ...state, plexoRoles: action.payload };
    default:
      return state;
  }
};