import React from 'react';
import { makeStyles } from '@material-ui/core';

import LogoMenu from '../../assets/logo-menu.png';
const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 28,
  }
});

const LogoIcon = () => {
  const classes = useStyles();

  return (<img className={classes.img} src={LogoMenu} />);
};

export default LogoIcon;
