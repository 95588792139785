import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
  }),
);
type Props = Partial<FieldExtensionComponentProps<string>> & {
  required: boolean | undefined;
  inputLabel?: string;
  payload: any;
  selectItemValue: string;
  selectItemContent: string;
  helperText: string;
};
export const SelectControl = ({
  required,
  inputLabel,
  payload,
  selectItemValue,
  selectItemContent,
  onChange,
  helperText,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState<string>('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const getValueFromSelectItemValue = (selectItemValue: any) => {
    if (typeof selectItemValue === 'object' && selectItemValue !== null) {
      return JSON.stringify(selectItemValue);
    }
    return selectItemValue;
  };

  return (
    <div>
      <FormControl
        fullWidth
        required={required}
        className={classes.formControl}
      >
        <InputLabel id="demo-controlled-open-select-label">
          {inputLabel}
        </InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={handleChange}
          value={selectedValue}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {payload.map((item: any) => (
            <MenuItem
              key={`${getValueFromSelectItemValue(item[selectItemValue])}-${
                item[selectItemContent]
              }`}
              value={`${item[selectItemContent]}, ${getValueFromSelectItemValue(
                item[selectItemValue],
              )}`}
            >
              {item[selectItemContent]}
            </MenuItem>
          ))}
        </Select>

        <FormHelperText id="demo-controlled-open-select">
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};
