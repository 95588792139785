import React from 'react';
import { makeStyles } from '@material-ui/core';

import LogoBancolombia from '../../assets/logo-bancolombia.png';

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 20,
  }
});
const LogoFull = () => {
  const classes = useStyles();

  return (<img className={classes.img} src={LogoBancolombia} />);
};

export default LogoFull;
