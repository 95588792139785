import { useApi } from "@backstage/core-plugin-api";
import { plexoApiRef } from "../api/plexoApiRef";
import { useEffect, useState } from "react";
import {IApprovers} from "../api/interfaces/data/SecurityGroups";


export const useGetApprovers = () => {

  const plexoBackend: any = useApi(plexoApiRef);
  const [areApproversLoading, setAreApproversLoading] = useState(true);
  const [approvers, setApprovers] = useState<IApprovers[]>([]);

  const fetchData = () => {
    setAreApproversLoading(true);
    plexoBackend.getApprovers()
      .then((response:IApprovers[])=>setApprovers(response))
      .finally(()=>setAreApproversLoading(false))
  }

  useEffect(() => {
    fetchData();
  }, []);
  return {
    approvers,
    areApproversLoading,

  }
};