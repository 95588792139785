import {BackstageUserIdentity} from "@backstage/core-plugin-api";

export const setBackstageUserIdentity = (backstageUserIdentity: BackstageUserIdentity) => {
  return {
    type: 'SET_BACKSTAGE_USER_IDENTITY' as const,
    payload: backstageUserIdentity
  }
};

export const setPlexoRoles = (plexoRoles: string[]) => {
  return {
    type: 'SET_PLEXO_ROLES' as const,
    payload: plexoRoles
  }

};
