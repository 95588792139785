import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { SelectControl } from './components/SelectControl';
import { useGetApprovers, useGetAccountsGroup } from '../../../hooks';
import { useFetchData } from './hooks/useFetchData';
import {getUiOptions, OptionsType} from './functions/functions';

export const CustomSelect = ({
  required,
  uiSchema,
  onChange,
}: FieldExtensionComponentProps<string>) => {
  const options = uiSchema['ui:options'] as OptionsType;

  const inputLabel = getUiOptions<string | undefined>(options, 'inputLabel', '');
  const selectItemValue = getUiOptions<string>(options, 'selectItemValue', '');
  const selectItemContent = getUiOptions<string>(options, 'selectItemContent', '');
  const helperText = getUiOptions<string>(options, 'helperText', '');
  const url = getUiOptions<string>(options, 'url', '');
  const endpoint = getUiOptions<string | undefined>(options, 'endpoint', '');
  const isPlexo = getUiOptions<boolean | undefined>(options, 'isPlexo', false);


  const { approvers } = useGetApprovers();
  const { accountsGroup } = useGetAccountsGroup();
  const { data } = useFetchData({ url: url });

  let payload;

  if (isPlexo && endpoint === 'approvers') {
    payload = approvers;
  } else if (isPlexo && endpoint === 'accounts-group') {
    payload = accountsGroup;
  } else {
    payload = data;
  }

  return (
    <SelectControl
      onChange={onChange}
      required={required}
      inputLabel={inputLabel}
      payload={payload}
      selectItemValue={selectItemValue}
      selectItemContent={selectItemContent}
      helperText={helperText}
    />
  );
};
