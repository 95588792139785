import { useState } from "react";
import {BackstageUserIdentity, identityApiRef, ProfileInfo, useApi} from "@backstage/core-plugin-api";

export const useBackstageIdentityApi = () => {

  const identityApi = useApi(identityApiRef)

  const [backstageUserIdentity, setBackstageUserIdentity] = useState<BackstageUserIdentity>();
  const [isBackstageUserIdentityLoading, setIsBackstageUserIdentityLoading] = useState(true);
  const [profileInfo, setProfileInfo] = useState<ProfileInfo>();
  const [isProfileInfoLoading, setIsProfileInfoLoading] = useState(true);
  const [credentials, setCredentials] = useState<{token?: string}>()
  const [areCredentialsLoading, setAreCredentialsLoading] = useState(true);

  const getProfileInfo = () => {
    identityApi.getProfileInfo()
      .then((profileInfo:ProfileInfo)=>{
        setProfileInfo(profileInfo)
      })
      .finally(() => setIsProfileInfoLoading(false));
  }


  const getBackstageUserIdentity = () => {
    identityApi.getBackstageIdentity()
      .then((backstageUserIdentity:BackstageUserIdentity)=>{
        setBackstageUserIdentity(backstageUserIdentity)
      })
      .finally(() => setIsBackstageUserIdentityLoading(false));
  }

  const getCredentials = () => {
    identityApi.getCredentials()
      .then((credentials:{token?: string}) => {
        setCredentials(credentials)
      })
      .finally(()=>setAreCredentialsLoading(false))
  }


  return {
    getBackstageUserIdentity,
    backstageUserIdentity,
    isBackstageUserIdentityLoading,
    getProfileInfo,
    profileInfo,
    isProfileInfoLoading,
    getCredentials,
    credentials,
    areCredentialsLoading,

  }
};