import {useEffect, useState} from 'react';

type Props = {
  url: string
}

export const useFetchData = ({url}:Props) => {

  const [data, setData] = useState([]);

  useEffect(() => {

    fetchData().then((response:any) => {
      setData(response)
    });
  }, [url]);


  const fetchData = async () => {
    const dataFetch = await fetch(url);
    return dataFetch.json()
  };


  return {
    data,
  }
};