import { createApiRef } from "@backstage/core-plugin-api";

export const OpexApiRef = createApiRef<OpexBackendApi>({
  id: 'plugin.opex.service',
});

export const CMDBApiRef = createApiRef<CMDBBackendApi>({
  id: 'plugin.cmdb.service',
});
// Interfaz para mapear títulos de hallazgos por categoría y subcategoría
export interface FindingTitlesType {
  EFICIENCIA: {
    [key: string]: string;
  };
  RENDIMIENTO: {
    [key: string]: string;
  };
  OBSOLESCENCIA: {
    [key: string]: string;
  };
  'TOLERANCIA A FALLOS': {
    [key: string]: string;
  };
  'RESILIENCIA': {
    [key: string]: string;
  };
}

export const dicDetails : {[key:string]:string[]} = {
  "rs-ec2-01": ["Tipo Instancia:", "Plataforma:", "Memoria:", "Eficiencia"],
  "co-ebs-01": ["Tipo Volumen:", "Tamaño Volumen (GB):", "IOPS:", "Eficiencia"],
  "co-ebs-02": ["Tipo Volumen:", "Tamaño Volumen (GB):", "IOPS:", "Eficiencia"],
  "tc-ebs-01": ["Tipo Volumen:", "Tamaño Volumen (GB):", "IOPS:", "Eficiencia"],
  "ta-rds-01": ["Tipo Instancia:", "Almacenamiento (GB):", "Dias ultima conexión:", "Eficiencia"],
  "ia-ec2-01": ["TimeUp (AVG) Last Week:", "TimeDown (AVG) Last Week:", "EC2/ASG Instance:", "Eficiencia"],
  "ia-rds-01": ["TimeUp (AVG) Last Week:", "TimeDown (AVG) Last Week:", "Db Instance Status:", "Eficiencia"],
  "cm-s3-01": ["Bucket Versioning:", "Non Current Version Expiration:", "Objects Count:", "Eficiencia"],
  "ta-asg-01": ["Health Check:", "Load Balancer Associated:", "CheckName:", "Rendimiento"],
  "ta-elb-01": ["Load Balancer Associated:", "Reason:", "CheckName:", "Tolerancia"],
  "ta-elb-0": ["# of Zones:", "CheckName:", "CheckName:", "Tolerancia"],
  "ta-rds-02": ["Multi-AZ:", "Region/AZ:", "VPC ID:", "Tolerancia"],
  "ta-elb-02": ["Load Balancer Name:", "Reason:", "CheckName:", "Tolerancia"],
  "ta-lambda-01": ["Average daily Invokes:", "VPC ID:", "", "Tolerancia"],
  "ta-mq-01": ["Fault Tolerance:", "Deployment Mode:", "Broker Engine Type:", "Tolerancia"],
  "ta-ebs-01": ["Volume Type:", "Volume Size(GB):", "Volume Throughput", "Rendimiento"],
  "ta-cfn-01": ["Cache Behavior Path Pattern:", "Headers:", "Volume Throughput", "Rendimiento"],
  "ta-ec2-01": ["Total Inbound Rules:", "Total Outbound Rules:", "VPC ID:", "Rendimiento"],
  "ta-efs-01": ["Throughput Mode:", "EFS File System ID:", "CheckName:", "Rendimiento"],
  "ta-s3-01": ["Data Transfer Out (GB):", "Ratio of Transfer to Storage:", "S3 Storage (GB):", "Rendimiento"],
  "co-ec2-01": ["Performance risk:", "Instance Type:", "Reason:", "Rendimiento"],
  "ta-lambda-02": ["Deprecation Date:", "Days to Deprecation:", "Runtime:", "Obsolencia"],
  "rm-rds-01": ["CACertificateIdentifier:", "ValidTill:", "PreferredMaintenanceWindow:", "Obsolencia"],
  "cfg-stk-01": ["Stack status:", "Stack Drift Status:", "Months since deployment:", "Obsolencia"],
  "rm-rds-02": ["deprecationStatus:", "Engine:", "Engine Version:", "Obsolencia"],
  "eks-k8s-01": ["Details 1:", "Details 2:", "Details 3:", "Resiliencia"]
}


// Definir el objeto findingTitlesMap utilizando la interfaz FindingTitlesType. Mapeo de títulos de hallazgos por Pilares (clave-Valor)
export const findingTitlesMap: FindingTitlesType = {
  'EFICIENCIA': {
    'rs-ec2-01': 'EC2 Sobreaprovisionado',
    'co-ebs-01': 'EBS sobreaprovisionado',
    'co-ebs-02': 'EBS gp2',
    'tc-ebs-01': 'EBS huérfano',
    'ta-rds-01': 'RDS inactiva',
    'ia-ec2-01': 'EC2 sin apagar en dev-qa',
    'ia-rds-01': 'RDS sin apagar en dev-qa',
    'cm-s3-01': 'S3 sin política de expiración de versiones u objetos',
  },
  'RENDIMIENTO': {
    'ta-ec2-01': 'ELB mal distribuido en AZs',
    'ta-ebs-01': 'ASG sin health en ELB',
    'ta-cfn-01': 'ELB sin connection draining',
    'ta-s3-01': 'ELB en solo 1 AZ',
    'ta-efs-01': 'RDS sin MultiAZ',
    'co-ec2-01': 'EC2 Poco Aprovisionadas',
  },
  'OBSOLESCENCIA': {
    'ta-lambda-02': 'Lambda con runtime obsoleto',
    'rm-rds-01': 'RDS certificados a vencerse',
    'rm-rds-02': 'Motor RDS obsoleto',
    'cfg-stk-01': 'Stack con estado fallido',
  },
  'TOLERANCIA A FALLOS': {
    'ta-rds-02': 'RDS sin MultiAZ',
    'ta-elb-01': 'ELB sin connection draining',
    'ta-elb-02': 'ELB en solo 1 AZ',
    'ta-elb-03': 'ELB mal distribuido en AZs',
    'ta-asg-01': 'ASG sin health en ELB',
    'ta-lambda-01': 'Lambda sin MultiAZ',
    'ta-mq-01': 'BroquerMQ sin MultiAZ',
  },  
'RESILIENCIA': {
  'eks-k8s-01': 'App contiene hallazgos de practicas k8s',
 },
};

// Mapeo de títulos de pilares
export const pilarMap: { [key: string]: string } = {
  'TOLERANCIA A FALLOS': 'Tolerance',
  'EFICIENCIA': 'Efficiency',
  'RENDIMIENTO': 'Performance',
  'OBSOLESCENCIA': 'Obsolescence',
  'CICLO DE VIDA': 'Lifecycle',
  'OBSERVABILIDAD': 'Observability',
  'CLOUD NATIVE': 'CloudNative'
};


export type scoreType = {
  findingId: string;
  applicationcode: string;
  applicationName: string;
  findingTitle: string;
  findingpilar: string;
  countFindings: number;
  promedioponderado: number;
}

export type FindingType = {
  ambiente: string;
  ldcResponsable: string;
  findingDescription: string;
  accountid: string;
  cumplimiento: string;
  resourceid: string;
  findingType: string;
  clasificacionDisponibilidad: string;
  findingDate: string;
  projectName: string;
  sort_key_add: string;
  name: string;
  findingDetails3: string;
  findingSolution: string[];
  findingDetails2: string;
  findingpilar: string;
  findingId: string;
  clasificacionConfidencialidad: string;
  findingDetails1: string;
  applicationcode: string;
  findingExplain: string;
  resourcetype: string;
  applicationName: string;
  findingTitle: string;
  creatorMail: string;
  month: string;
  accountName: string;
  year: string;
  day: string;
  relativeWeight: number;
  arn: string;

}


export type ApplicationType = {
  applicationname: string;
  applicationcode: string;
  businessarea: string;
}

export interface OpexBackendApi {
  getScoresFromApi(appcode: string): Promise<scoreType[]>;
  getScoreResult(): scoreType[];
  getFindingsFromApi(appcode: string, pilar:string, findingId:string): Promise<FindingType[]>;
}

export interface CMDBBackendApi {
  getApplications(): Promise<ApplicationType[]>;
}