import { createContext } from 'react';
import {BackstageUserIdentity} from "@backstage/core-plugin-api";

export interface GlobalContextType {
  backstageUserIdentity?: BackstageUserIdentity;
  plexoRoles?: string[];
  setPlexoRoles?: (roles: string[]) => void;
}
export const defaultValue: GlobalContextType = {
  backstageUserIdentity: {
    type: 'user',
    userEntityRef:'',
    ownershipEntityRefs:[],
  },
  plexoRoles: [],
};

export const GlobalContext = createContext<GlobalContextType>(defaultValue);