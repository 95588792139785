import { DiscoveryApi, IdentityApi } from "@backstage/core-plugin-api";
import { OpexBackendApi, scoreType, FindingType } from "../types";

export class OpexBackendClient implements OpexBackendApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  private __scoreResult: any;
  private __scoreFindings: any;

  constructor(options: {discoveryApi: DiscoveryApi, identityApi:IdentityApi }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }
  private async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return await response.json();
  }

  private __setScoreResult(scoreResult: scoreType[]) {
    this.__scoreResult = scoreResult;
  }

  public getScoreResult(): any {
    return this.__scoreResult;
  }

  async getScoresFromApi(appcode: string): Promise<scoreType[]> {
    const url = `${await this.discoveryApi.getBaseUrl('opex')}/opexscores/${appcode}`;
    // const url = `http://localhost:7008/api/opex/opexscores/${appcode}`;
    const token = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token.token}`
      }
    });
    this.__setScoreResult(await this.handleResponse(response));
    return this.getScoreResult();
  }

  async getFindingsFromApi(appcode: string, pilar:string, findingId:string): Promise<FindingType[]> {
    const url = `${await this.discoveryApi.getBaseUrl('opex')}/getFindings/${appcode}/${pilar}/${findingId}`;
    // const url = `http://localhost:7008/api/opex/getFindings/${appcode}/${pilar}/${findingId}`;
    const token = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token.token}`
      }
    });
    this.__scoreFindings = await this.handleResponse(response);
    return this.__scoreFindings;
  }
}