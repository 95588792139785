import { DiscoveryApi, IdentityApi } from "@backstage/core-plugin-api";
import { IPlexoBackend } from "./interfaces/types/IPlexoBackend";
import { IDeployments } from "./interfaces/data/Deployments";
import { IApplicationData } from "./interfaces/data/ApplicationData";
import { IApprovers } from "./interfaces/data/SecurityGroups";
import { IAccountsGroup } from "./interfaces/data/AccountsGroup";
import { IdownloadDiagram } from "./interfaces/data/Diagram";
import { IDeleteApplication, IDtoDeleteApp } from "./interfaces/data/DeleteApplication";
import { IAzuresSearchUsers } from "./interfaces/data/UsersAzure";
import { IApplication } from "./interfaces/data/Applications";
import { IBodyUpdateApprover } from "./interfaces/data/UpdateApprover";
import {IBodyCancelState, IResponseCancelState} from "./interfaces/data/CancelState";
import {IApprovals, IBodyApprovalDeployment} from "./interfaces/data/Approvals";

export class PlexoBackendClient implements IPlexoBackend {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor({ discoveryApi, identityApi }: { discoveryApi: DiscoveryApi, identityApi: IdentityApi }) {
    this.discoveryApi = discoveryApi;
    this.identityApi = identityApi;

  }

  async getAllApplications(): Promise<IApplication[]> {
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/all-applications`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`
        }
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching applications list: ${error}`);
      return []
    }
  };

  async getDeployments(appCode: string): Promise<IDeployments[]> {
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/deployments/${appCode}`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`
        }
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching deployments list: ${error}`);
      return []
    }

  };

  async getDeploymentByReviewId({ appCode, revisionId, deploymentId }: { appCode: string, revisionId: string, deploymentId: string }): Promise<IDeployment> {
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/deployments/reviewId/${appCode}/${revisionId}/${deploymentId}`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`
        }
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching deployment review: ${error}`);
      return {} as IDeployment
    }

  };

  async getApplicationData(appCode: string): Promise<IApplicationData[]> {
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/application/get-application/${appCode}`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`
        }
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching application data:${error}`);
      return []
    }

  };

  async postDeleteApplication(payload: IDtoDeleteApp): Promise<IDeleteApplication> {
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/application/delete`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching delete application: ${error}`);
      return {} as IDeleteApplication
    }
  }

  async getApprovers(): Promise<IApprovers[]> {
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/azure/approvers`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`
        }
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching approvers: ${error}`);
      return []
    }

  };

  async getAccountsGroup(): Promise<IAccountsGroup[]> {
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/application/accounts-group`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`
        }
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching accounts group: ${error}`);
      return []
    }

  };

  async getDownloadDiagram({ appCode, revisionId, type }: { appCode: string, revisionId: string, type: string }): Promise<IdownloadDiagram> {
    try {
      if (type === '') {
        return { url: null }
      }
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/deployments/download-diagram/${appCode}/${revisionId}/${type}`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`
        }
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching download diagram: ${error}`);
      return {} as IdownloadDiagram
    }

  };

  async getCfnTemplate({ appCode, revisionId, type }: { appCode: string, revisionId: string, type: string }): Promise<IdownloadDiagram> {
    try {
      if (type === '') {
        return { url: null }
      }
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/deployments/get-cfn-template/${appCode}/${revisionId}/${type}`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`
        }
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching download template: ${error}`);
      return {} as IdownloadDiagram
    }

  };

  async getSearchUsersAzure({ username }: { username: string }): Promise<IAzuresSearchUsers[]> {
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/azure/users/${username}`;

      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`
        }
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching azure users: ${error}`);
      return [] as IAzuresSearchUsers[]
    }
  };

  async postAddOwnerContributor({ appCode, userId, type }: {appCode: string, userId: string, type: string }): Promise<any> {
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/application/add-owner-contributor`;

      const bearerToken = await this.identityApi.getCredentials();

      const body = {
        appCode,
        userId,
        type
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching  add owner contributor: ${error}`);
      return [] as any[]
    }
  };

  async deleteOwnerContributor({ appCode, userId, type }: { appCode: string, userId: string,type: string }): Promise<any> {
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/application/delete-owner-contributor/${appCode}/${userId}/${type}`;

      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`
        }
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();

    } catch (error) {
      console.error(`An error occurred while fetching delete owner contributor: ${error}`);
      return [] as any[]
    }
  };

  async postUpdateApprover(body:IBodyUpdateApprover): Promise<any>{
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/application/update-approver`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();
    } catch (error) {
      console.error(`An error occurred while fetching update approver: ${error}`);
      return {} as any
    }
  }

  async postCancelDeployment(body:IBodyCancelState): Promise<IResponseCancelState>{
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/deployments/cancel-state-machine`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();
    } catch (error) {
      console.error(`An error occurred while fetching cancel deployment: ${error}`);
      return {} as IResponseCancelState
    }
  }

  async getPendingDeployments(): Promise<IApprovals[]> {

    const profileInfoEmmail = await this.identityApi.getProfileInfo().then(profileInfo => {
      return profileInfo.email ? profileInfo.email : "";
    });

    const userId = await this.getSearchUsersAzure({username: profileInfoEmmail}).then(response => {
      if(response.length <= 0) {
        return '-'
      }
      return response[0].id;
    });


    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/deployments/get-pending-deployments-by-user/${userId}`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`
        }
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();
    } catch (error) {
      console.error(`An error occurred while fetching pending deployments: ${error}`);
      return [] as IApprovals[]
    }
  }

  async postApproveDeployment(body:IBodyApprovalDeployment): Promise<IApprovals> {
    try {
      const url = `${await this.discoveryApi.getBaseUrl('plexo')}/deployments/approve-deployment`;
      const bearerToken = await this.identityApi.getCredentials();

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`${response.status}`)
      }

      return await response?.json();
    } catch (error) {
      console.error(`An error occurred while fetching approve deployment: ${error}`);
      return {} as IApprovals
    }
  }
}