import { DiscoveryApi, IdentityApi } from "@backstage/core-plugin-api";
import { CMDBBackendApi, ApplicationType } from "../types";

export class CMDBBackendClient implements CMDBBackendApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: { discoveryApi: DiscoveryApi, identityApi: IdentityApi }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }
  private async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return await response.json();
  }

  async getApplications(): Promise<ApplicationType[]> {
    const url = `${await this.discoveryApi.getBaseUrl('cmdb')}/getApplications`;
    // const url = `http://localhost:7007/api/cmdb/getApplications`;
    const token = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token.token}`
      }
    });
    const applications = await this.handleResponse(response);
    return applications;
  }


}