import React from 'react';
import {Navigate, Route} from 'react-router';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from '@backstage/plugin-catalog';
import {CatalogImportPage, catalogImportPlugin,} from '@backstage/plugin-catalog-import';
import {ScaffolderFieldExtensions, ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {DefaultTechDocsHome, TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage,} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';

import {AlertDisplay, AutoLogout, OAuthRequestDialog, SignInPage} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';
import {CssBaseline} from '@material-ui/core';
import {bancolombiaLightTheme} from './theme/bancolombia-light';
import {bancolombiaDarkTheme} from './theme/bancolombia-dark';
import {authProviders} from './identityProviders';
import {ToolboxPage} from '@drodil/backstage-plugin-toolbox';
import {UnifiedThemeProvider} from "@backstage/theme";
import {CustomSelectFieldExtension, PlexoViewPage} from '@internal/backstage-plugin-plexo-view';

import './App.css'
import {OpexPage} from '@internal/backstage-plugin-opex'
import {BcVisionPdnPage} from '@internal/backstage-plugin-bc-vision';
import {GlobalProvider} from "./context";
import {DevToolsPage} from '@backstage/plugin-devtools';
import {CatalogUnprocessedEntitiesPage} from '@backstage/plugin-catalog-unprocessed-entities';
import {customDevToolsPage} from "./components/devtools/CustomDevToolsPage";
import {DefaultFilters} from "@backstage/plugin-catalog-react";

const app = createApp({
  apis,
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          providers={[...authProviders]}
          title="Select a sign-in method"
          align="center"
        />
      );
    },
  },

  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'light',
      title: 'Light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={bancolombiaLightTheme}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'dark',
      title: 'Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={bancolombiaDarkTheme}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      ),
    },
  ],
});

/**
 * Defines default catalog filters for the catalog plugin
 * {@link https://backstage.io/docs/features/software-catalog/catalog-customization/#customize-filters}
 */
const setCatalogFilters = (
  <>
    <DefaultFilters initiallySelectedNamespaces={['default']}/>
  </>
);

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={
      <CatalogIndexPage
        filters={setCatalogFilters}
      />
    }/>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Recommended',
              filter: entity =>
                entity?.metadata?.tags?.includes('recommended') ?? false,
            },
            {
              title: 'DevOps',
              filter: entity =>
                entity?.metadata?.tags?.includes('devops-operations') ?? false,
            },
            {
              title: 'Infrastructure',
              filter: entity =>
                entity?.metadata?.tags?.includes('infrastructure') ?? false,
            },
            {
              title: 'Integration',
              filter: entity =>
                entity?.metadata?.tags?.includes('integration') ?? false,
            },
            {
              title: 'Plexo',
              filter: entity =>
                entity?.metadata?.tags?.includes('plexo') ?? false,
            },
          ]}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <CustomSelectFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />

    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/toolbox" element={<ToolboxPage />} />
    <Route path="/opex" element={<OpexPage />} />
    <Route path="/bc-vision" element={<BcVisionPdnPage />} />
    <Route path="/plexo-view" element={<PlexoViewPage />} />
    <Route path="/devtools" element={<DevToolsPage/>}>
      {customDevToolsPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage/>}
    />;
  </FlatRoutes>
);

export default app.createRoot(
  <GlobalProvider>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AutoLogout
      idleTimeoutMinutes={10}
      useWorkerTimers={false}
      logoutIfDisconnected={false}/>
      <AppRouter>
        <Root>{routes}</Root>
      </AppRouter>
  </GlobalProvider>
);

