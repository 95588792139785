import { useApi } from "@backstage/core-plugin-api";
import { plexoApiRef } from "../api/plexoApiRef";
import { useEffect, useState } from "react";
import {IAccountsGroup} from "../api/interfaces/data/AccountsGroup";


export const useGetAccountsGroup = () => {

  const plexoBackend: any = useApi(plexoApiRef);
  const [areAccountsGroupLoading, setAreAccountsGroupLoading] = useState(true);
  const [accountsGroup, setAccountsGroup] = useState<IAccountsGroup[]>([]);

  const fetchData = () => {
    setAreAccountsGroupLoading(true);
    plexoBackend.getAccountsGroup()
      .then((response:IAccountsGroup[])=>setAccountsGroup(response))
      .finally(()=>setAreAccountsGroupLoading(false))
  }

  useEffect(() => {
    fetchData();
  }, []);
  return {
    accountsGroup,
    areAccountsGroupLoading,

  }
};