import React, {PropsWithChildren} from 'react';
import {makeStyles} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import CloudIcon from '@material-ui/icons/Cloud';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {Settings as SidebarSettings, UserSettingsSignInAvatar,} from '@backstage/plugin-user-settings';
import {SidebarSearchModal} from '@backstage/plugin-search';
import {
  Link,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CardTravel from '@material-ui/icons/CardTravel';
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';
import BuildIcon from '@material-ui/icons/Build';
import {RequirePermission} from "@backstage/plugin-permission-react";
import {devToolsAdministerPermission} from "@backstage/plugin-devtools-common";

const HEIGHT_MULTIPLIER = 3;
const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: sidebarConfig.logoHeight * HEIGHT_MULTIPLIER,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

//datalayer menu
declare global {
  interface Window {
    dataLayer: any[];
  }
}
//datalayer
const handleMenuClick = (elemento : string) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "SEND_MENU_BANCOLOMBIA",
      activo: "bancolombia",
      nombreFlujo: "catalog", 
      seccion: "kaizen",
      elemento: elemento,
    });
  }
};
// dt fin 

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search" >
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="catalog" text="Home" onClick={() => handleMenuClick('home')}/>
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" onClick={() => handleMenuClick('apis')}/>
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" onClick={() => handleMenuClick('docs')}/>
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." onClick={() => handleMenuClick('create')}  />
        <SidebarItem icon={CardTravel} to="toolbox" text="ToolBox" onClick={() => handleMenuClick('toolbox')}  />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarItem icon={NewReleasesOutlinedIcon} to="opex" text="OpEx" onClick={() => handleMenuClick('opex')} />
        <SidebarItem icon={CloudIcon} to="plexo-view" text="PleXo" onClick={() => handleMenuClick('plexo')}/>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
        <RequirePermission
          permission={devToolsAdministerPermission}
          errorPage={<></>}
        >
          <SidebarItem icon={BuildIcon} to="devtools" text="DevTools"/>
        </RequirePermission>
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
