import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const bcVisionPlugin = createPlugin({
  id: 'bc-vision',
  routes: {
    root: rootRouteRef,
  },
});

export const BcVisionDevPage = bcVisionPlugin.provide(
  createRoutableExtension({
    name: 'BcVisionDevPage',
    component: () =>
      import('./components/BC-Vision').then(m => m.EntityBCVisionDevContent),
    mountPoint: rootRouteRef,
  }),
);

export const BcVisionQaPage = bcVisionPlugin.provide(
  createRoutableExtension({
    name: 'BcVisionQaPage',
    component: () =>
      import('./components/BC-Vision').then(m => m.EntityBCVisionQaContent),
    mountPoint: rootRouteRef,
  }),
);

export const BcVisionPdnPage = bcVisionPlugin.provide(
  createRoutableExtension({
    name: 'BcVisionPdnPage',
    component: () =>
      import('./components/BC-Vision').then(m => m.EntityBCVisionPdnContent),
    mountPoint: rootRouteRef,
  }),
);
