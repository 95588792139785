import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import {plexoApiRef} from "./api/plexoApiRef";
import {PlexoBackendClient} from "./api/PlexoBackendClient";
import {scaffolderPlugin} from "@backstage/plugin-scaffolder";
import {createScaffolderFieldExtension} from "@backstage/plugin-scaffolder-react";
import {CustomSelect} from "./components/customFieldExtension/CustomSelect/CustomSelect";

export const plexoViewPlugin = createPlugin({
  id: 'plexo-view',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: plexoApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef
      },
      factory:({discoveryApi, identityApi})=>
        new PlexoBackendClient({discoveryApi,identityApi })
    },)
  ],
});

export const PlexoViewPage = plexoViewPlugin.provide(
  createRoutableExtension({
    name: 'PlexoViewPage',
    component: () =>
      import('./layout/PlexoView').then(m => m.PlexoView),
    mountPoint: rootRouteRef,
  }),
);

export const CustomSelectFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CustomSelectFieldExtension',
    component: CustomSelect,
  }),
);