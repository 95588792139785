import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef
} from '@backstage/core-plugin-api';

import { CMDBApiRef, OpexApiRef } from './types';
import { OpexBackendClient } from './api/opexBackendClient';
import {CMDBBackendClient} from "./api/cmdbBackendClient";

import { rootRouteRef } from './routes';
//import { opexbackApiRef } from '../../opexback-backend/src/service/types';
//import { opexbackService } from '../../opexback-backend/src/service/opexback.service';

export const opexPlugin = createPlugin({
  id: 'opex',
  apis: [
    createApiFactory({
      api: OpexApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef
      },
      factory: ({ discoveryApi,identityApi  }) =>
        new OpexBackendClient({ discoveryApi, identityApi }),
    }),
    createApiFactory({
      api: CMDBApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef
      },
      factory: ({ discoveryApi,identityApi  }) =>
        new CMDBBackendClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const OpexComponent = opexPlugin.provide(
  createRoutableExtension({
    name: 'OpexComponent',
    component: () =>
      import('./components/OpexComponent').then(m => m.OpexComponent),
    mountPoint: rootRouteRef,
  }),
);

export const OpexPage = opexPlugin.provide(
  createRoutableExtension({
    name: 'OpexPage2',
    component: () =>
      import('./components/OpexComponent').then(m => m.OpexApp),
    mountPoint: rootRouteRef,
  }),
);
